.App {
  text-align: center;
}

.App-header {
  background-color: #d6d6d66e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #212529;
}

.chat-container {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 600px;
  height: 500px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.form-container {
  width: 100%;
}


.input-box {
  width: 100%;
  height: 40px;
  border: 2px solid #ced4da;
  border-radius: 5px 0 0 5px;
  resize: none;
  background-color: white;
  color: black;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box; 
}

.input-box::placeholder {
  color: #6c757d;
  opacity: 1; 
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
}

.submit-button {
  width: 40px;
  height: 40px;
  border: 2px solid #ced4da;
  border-left: none;
  border-radius: 0 5px 5px 0;
  background-color: #212529;
  color: white;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.submit-button:hover {
  background-color: #343a40;
}



.input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative; 
}

.timer {
  position: absolute;
  bottom: -27px; 
  left: 5px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
  color: #212529;
}


.settings {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.model-select {
  height: 40px;
  border: 2px solid #ced4da;
  border-radius: 5px;
  background-color: white;
  color: black;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
}

.output-box {
  margin-top: 20px;
  position: relative;  
  width: 100%;
  height: 370px;
  border: 2px solid #ced4da;
  border-radius: 5px;
  background-color: rgb(243, 243, 243);
  padding: 10px;
  box-sizing: border-box; 
}

.image-box {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  image-rendering: pixelated;
}


.download-button {
  position: absolute;
  bottom: 30px;
  right: 10px;
  padding: 3px 5px;
  background-color: #212529;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
}

.download-button:hover {
  background-color: #343a40;
}


.download-transparent-button{

  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 3px 5px;

  background-color: #212529;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
}

.download-transparent-button:hover {
  background-color: #343a40;
}

.dimesions {
  position: absolute;
  bottom: 1%;
  left: 10px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
  font-weight: bold;
}

.note_prompt{
  position: absolute;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
}
.slider-container {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-left: auto;
}

.slider {
  width: 150px;
  margin-right: 10px; 
}

.pixelate-button {
  margin-left: 10px;
  padding: 5px 10px;
  border: 2px solid #ced4da;
  border-radius: 5px;
  background-color: #212529;
  color: white;
  cursor: pointer;
}

.pixelate-button:hover {
  background-color: #343a40;
}

.slider-text {
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
}
